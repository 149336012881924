import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/blackstone.png'
import thumb02 from '../assets/images/thumbs/welcome.png'
import thumb03 from '../assets/images/thumbs/millbrook.png'
import thumb04 from '../assets/images/thumbs/athena.png'
import thumb05 from '../assets/images/thumbs/forekast.png'


import full01 from '../assets/images/fulls/01.jpg'

const works = [
    { id: '0', source: full01, thumbnail: thumb04, link: 'https://athena.money', caption: 'Athena Money', description: 'Personal finance and budgeting application.'},
    { id: '4', source: full01, thumbnail: thumb05, link: 'https://forekast.vercel.app/', caption: 'Forekast AI', description: 'AI Tool to predict future performance of stocks.'},
    { id: '1', source: full01, thumbnail: thumb01, link: 'https://www.blackstonebus.com', caption: 'Blackstone Area Bus', description: 'Local bus system maps and routes.'},
    { id: '2', source: full01, thumbnail: thumb02, link: 'https://www.welcometofarmville.com', caption: 'Welcome To Farmville', description: 'Web app showing a catalog of coupons for college students.'},
    { id: '3', source: full01, thumbnail: thumb03, link: 'https://www.millbrooksupport.com', caption: 'Millbrook Support', description: 'Medical and healthcare staffing agency.'},

];

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Portfolio by Jason Ellington"
        const siteDescription = "Site description"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>About Me</h2>
                        </header>
                        <p>Hi, I'm Jason and I am a Software Engineer working at CB Insights in New York City. In my spare time I enjoy doing freelance web development, finding trendy restaurants around the city, and traveling to cool places. I greatly enjoy building websites for all kinds of businesses. Please checkout my portfolio and contact me if you have any questions.</p>
                    </section>

                    <section id="two">
                        <h2>Recent Work</h2>

                        <Gallery images={works.map(({ id, source, thumbnail, link, caption, description }) => ({
                            source,
                            thumbnail,
                            link,
                            caption,
                            description
                        }))} />

                        {/* <ul className="actions">
                            <li><a href="#" className="button">Full Portfolio</a></li>
                        </ul> */}
                    </section>

                    <section id="three">
                        <h2>Get In Touch</h2>
                        <div className="row">
                            <div className="12u 12u$(small)">
                                <form
                                  name="contact"
                                  method="post"
                                  data-netlify="true"
                                  data-netlify-honeypot="true"
                                  >
                                    <input type="hidden" name="bot-field" />
                                    <input type="hidden" name="form-name" value="contact" />
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                    <ul className="actions" style={{padding: '5px'}}>
                                        <li><input type="submit" value="Send Message" /></li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex
